@charset "UTF-8";

a {
  outline: none; }

body {
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #4e4d4d;
  background: url(../img/bg-main.gif) top repeat-x; }

div {
  &#container {
    width: 988px;
    margin: 0 auto; }

  &#header {
    height: 365px;
    background-image: url(../img/testata_new_piano.jpg); }

  &#lang {
    height: 20px;
    text-align: right;
    padding-right: 10px;
    padding-top: 4px;
    color: white;
    font-size: 12px;

    img {
      border: 0;
      margin-left: 10px;
      height: 1.2em; } }

  &#menu {
    height: 44px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background-color: #DDD9CD;
    width: 988px;

    ul {
      margin: 0;
      padding: 0;

      li {
        float: left;
        display: block;

        a {
          &:link, &:visited {
            display: block;
            float: left;
            background-color: #DDD9CD;
            padding: 13px 6px;
            height: 16px;
            text-decoration: none;
            font-size: 14px;
            color: #4e4d4d; }

          &:hover {
            background-color: #fff; }

          &.active {
            background-color: white; } } } } }

  &#main {
    border-top: 1px solid #85705B;

    h1, h2 {
      font-size: 16px;
      font-weight: normal;
      margin: 0 0 10px 0;
      padding-bottom: 1px;
      border-bottom: 1px solid #DDD9CD;
      color: red; }

    h3 {
      font-weight: normal;
      margin-top: 30px;
      font-size: 14px;
      margin-bottom: 6px; } }

  &#colonna2 h2 img {
    border: 0;
    margin-left: 186px; }

  &#colonna3 h2 img {
    border: 0;
    margin-left: 216px; }

  &#main {
    form {
      label {
        color: #4E4B49;
        font-size: 12px;
        float: left;
        clear: both;
        width: 150px;
        margin-top: 10px; }

      .input {
        border: 1px solid #C0C0C0;
        width: 300px;
        float: left;
        margin-top: 10px; } }

    div {
      &.anteprima-news {
        font-size: 12px;
        clear: both;
        margin-bottom: 10px;
        height: 60px; }

      &.vetrina-news {
        font-size: 12px;
        clear: both;
        margin-bottom: 44px;
        height: 60px; }

      &.anteprima-news img, &.vetrina-news img {
        margin-right: 10px;
        border: 1px solid #4e4d4d;
        width: 72px;
        height: 72px; }

      &.anteprima-news span.data, &.vetrina-news span.data {
        color: red;
        display: block;
        padding-top: 0; }

      &.anteprima-news span.titolo-preview, &.vetrina-news span.titolo-preview {
        display: block;
        text-decoration: none;
        color: #4e4d4d;
        font-weight: bold;
        font-size: 13px; }

      &.anteprima-news span.titolo-preview a {
        &:link, &:visited {
          text-decoration: none;
          color: #4e4d4d;
          font-weight: bold;
          font-size: 13px; } }

      &.vetrina-news span.titolo-preview a {
        &:link, &:visited {
          text-decoration: none;
          color: #4e4d4d;
          font-weight: bold;
          font-size: 13px; } }

      &.anteprima-news span.titolo-preview a:hover, &.vetrina-news span.titolo-preview a:hover {
        text-decoration: underline;
        color: #4e4d4d;
        font-weight: bold; }

      &.anteprima-news span.testo-preview {
        font-style: italic; } }

    span {
      &.archivio {
        font-size: 12px;
        width: 280px;
        display: block;
        text-align: right;
        margin-top: 20px; }

      &.archivio-interno {
        font-size: 12px;
        width: 606px;
        display: block;
        text-align: right;
        margin-top: 20px; }

      &.archivio a {
        &:link, &:visited {
          text-decoration: none;
          color: red; } }

      &.archivio-interno a {
        &:link, &:visited {
          text-decoration: none;
          color: red; } }

      &.archivio a:hover, &.archivio-interno a:hover {
        text-decoration: underline;
        color: red; } } }

  &.separatore {
    height: 20px; }

  &#colonna1 {
    width: 280px;
    min-height: 390px;
    float: left;
    padding-top: 30px;
    margin-bottom: 20px;
    background: url(../img/nota-big.gif) 0px 25px no-repeat; }

  &#colonna1b {
    width: 280px;
    min-height: 390px;
    float: left;
    padding-top: 30px;
    margin-bottom: 20px;

    img {
      width: 100%; } }

  &#colonna1 {
    p {
      font-size: 13px;
      padding: 10px;
      margin: 0;
      line-height: 1.4em; }

    ul.elenco li {
      font-size: 12px;
      line-height: 1.4em;
      padding-bottom: 4px; } }

  &#colonna2, &#colonna3 {
    width: 280px;
    min-height: 390px;
    float: left;
    padding-top: 30px;
    margin-left: 46px;
    margin-bottom: 20px; }

  &#colonna-interna {
    width: 631px;
    min-height: 342px;
    float: left;
    padding-top: 30px;
    margin-left: 46px;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 1.4em; }

  &#colonna-interna-big {
    width: 988px;
    min-height: 342px;
    padding-top: 30px;
    margin: 0 0 20px 0;
    font-size: 13px;
    line-height: 1.4em;

    h4.audio {
      font-size: 16px;
      font-weight: normal;
      margin: 0px 0 10px 0;
      padding-bottom: 1px;
      color: red;
      clear: both; } }

  &.audio {
    float: left;
    width: 240px;
    height: 110px;
    margin-right: 5px;
    margin-bottom: 15px;
    border: 1px solid #ECECEC; }

  &.separatore-audio {
    clear: both;
    display: block;
    height: 40px; }

  &.audio p {
    margin-bottom: 8px;
    padding-left: 8px;
    font-size: 11px; }

  &.video {
    border: 1px solid #ECECEC;
    width: 300px;
    float: left;
    margin-right: 5px; }

  &#colonna-interna {
    ul {
      margin-bottom: 50px; }

    img.foto-news {
      display: block;
      margin-bottom: 20px; }

    a {
      &:link, &:visited {
        color: red;
        text-decoration: none; }

      &:hover {
        text-decoration: underline; } }

    img.fotogallery {
      border: 0;
      text-decoration: none; } } }

p.paginazione {
  height: 30px;
  padding-top: 30px;
  display: block; }

div {
  &#footer {
    height: 35px;
    clear: both;
    border-top: 1px solid #4e4d4d;
    padding-top: 10px; }

  &#footer-container {
    margin: 0 auto;
    width: 962px; }

  &#footer span {
    &.social {
      float: left;

      img {
        border: 0; } }

    &.copyright {
      float: right;
      font-size: 12px;

      img {
        margin-right: 6px; }

      a {
        &:link, &:visited {
          text-decoration: none;
          color: red; }

        &:hover {
          text-decoration: underline;
          color: red; } } } } }
